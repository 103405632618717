import { Component, OnInit } from '@angular/core';
import { AlertController, NavController } from '@ionic/angular';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-recuperar',
  templateUrl: './recuperar.page.html',
  styleUrls: ['./recuperar.page.scss'],
})
export class RecuperarPage implements OnInit {

  email:string;

  constructor( public navCtrl: NavController, private _api:ApiService, public alertController:AlertController ) { }

  ngOnInit() {

  }

  login(){
    this.navCtrl.back();
  }

  recuperar(){

    let emailFormat = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if( emailFormat.test( this.email ) ){
      
      let send_data = { email: this.email};
      this._api.recuperaPass( send_data ).subscribe((data:any)=>{
        console.log(data);
        if( data.status ){
          this.presentAlert( data.mensaje );
        } 
      });

    }else{
      this.presentAlert( `El formato de Correo Electrónico es incorrecto` );
    }

  }

  async presentAlert( mensaje:string ) {
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: 'Mensaje',
      // subHeader: 'Subtitle',
      message: mensaje,
      buttons: ['OK']
    });

    await alert.present();

    const { role } = await alert.onDidDismiss();
    console.log('onDidDismiss resolved with role', role);
  }

}
